import { axios } from '@/plugins/vueaxios'
import Account from '@/models/economy/Account'

export default class AccountService {
  public static loadOne(id: number): Promise<Account> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/economy/accounts/' + id)
        .then((response) => {
          resolve(new Account(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static loadAll(): Promise<Account[]> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/economy/accounts?perPage=500')
        .then((response) => {
          const result = []
          response.data.data.forEach((v) => {
            result.push(new Account(v))
          })
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static create(data: Account): Promise<Account> {
    return new Promise((resolve, reject) => {
      axios
        .post('/v4/site/economy/accounts', data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static update(data: Account): Promise<Account> {
    return new Promise((resolve, reject) => {
      axios
        .put('/v4/site/economy/accounts/' + data.id, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
