export default class CardIssuer {
  public id: number
  public name: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.name = data.name ? (data.name as string) : null
  }

  public clone(): CardIssuer {
    const result = new CardIssuer()
    Object.assign(result, this)
    return result
  }
}
