export default class Account {
  public id: number
  public number: number
  public name: string
  public vatId: number
  public type: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.number = data.number ? (data.number as number) : null
    this.name = data.name ? (data.name as string) : ''
    this.vatId = data.vatId ? (data.vatId as number) : null
    this.type = data.type ? (data.type as string) : ''
  }

  public clone(): Account {
    const result = new Account()
    Object.assign(result, this)
    return result
  }

  public get numberAndName(): string {
    if (!this.number) {
      return ''
    }
    return this.number + ' - ' + this.name
  }

  public get isTypeVat(): boolean {
    return this.type === 'vat'
  }

  public get isTypePayment(): boolean {
    return this.type === 'payment'
  }

  public get isTypeCost(): boolean {
    return this.type === 'cost'
  }
}
