import { axios } from '@/plugins/vueaxios'
import PaymentAccountingRule from '@/models/economy/PaymentAccountingRule.ts'

export default class PaymentAccountingRuleService {
  public static loadOne(id: number): Promise<PaymentAccountingRule> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/economy/payment-accounting-rules/' + id)
        .then((response) => {
          resolve(new PaymentAccountingRule(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static loadAll(): Promise<PaymentAccountingRule[]> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/economy/payment-accounting-rules')
        .then((response) => {
          const result = []
          response.data.data.forEach((v) => {
            result.push(new PaymentAccountingRule(v))
          })
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static create(data: PaymentAccountingRule): Promise<PaymentAccountingRule> {
    return new Promise((resolve, reject) => {
      axios
        .post('/v4/site/economy/payment-accounting-rules', data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static update(data: PaymentAccountingRule): Promise<PaymentAccountingRule> {
    return new Promise((resolve, reject) => {
      axios
        .put('/v4/site/economy/payment-accounting-rules/' + data.id, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
