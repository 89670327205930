export default class PaymentAccountingRule {
  public id: number
  public accountId: number
  public paymentExtension: string
  public cardIssuerId: number
  public isDefault: boolean

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.accountId = data.accountId ? (data.accountId as number) : null
    this.paymentExtension = data.paymentExtension ? (data.paymentExtension as string) : ''
    this.cardIssuerId = data.cardIssuerId ? (data.cardIssuerId as number) : null
    this.isDefault = data.isDefault ? (data.isDefault as boolean) : false
  }

  public clone(): PaymentAccountingRule {
    const result = new PaymentAccountingRule()
    Object.assign(result, this)
    return result
  }
}
