



























































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import PaymentAccountingRuleService from '@/services/economy/PaymentAccountingRuleService'
import PaymentAccountingRule from '@/models/economy/PaymentAccountingRule'
import AccountService from '@/services/economy/AccountService'
import Account from '@/models/economy/Account'
import { axios } from '@/plugins/vueaxios'
import PaymentType from '@/models/payment/PaymentType'
import CardIssuer from '@/models/payment/CardIssuer'

@Component({})
export default class Edit extends Vue {
  private isSaving = false
  private rules = {}
  private data: PaymentAccountingRule = null
  private error = ''
  private accounts = []
  private paymentTypes = []
  private cardIssuers = []

  public created(): void {
    this.data = new PaymentAccountingRule()
    this.rules = {
      required: [(v) => !!v || this.$t('c:validation:This field is required')],
    }
    this.load()
  }

  private get id(): number {
    return this.$route.params.id ? parseInt(this.$route.params.id) : null
  }

  private get isNew() {
    return !!this.$route.meta.isNew
  }

  private load() {
    this.loadCardIssuers().then((cardIssuers) => {
      this.cardIssuers = [{ id: null, name: '' }]
      cardIssuers.forEach((t) => {
        this.cardIssuers.push(t)
      })
      this.loadPaymentTypes().then((types) => {
        this.paymentTypes = types
        AccountService.loadAll()
          .then((accounts) => {
            this.accounts = accounts
            PaymentAccountingRuleService.loadOne(this.id).then((item) => {
              this.data = item
            })
          })
          .catch((err) => {
            vxm.alert.onAxiosError(err)
          })
      })
    })
  }

  private loadPaymentTypes(): Promise<PaymentType[]> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/payment/payment-types')
        .then((response) => {
          const result = []
          response.data.data.forEach((v) => {
            result.push(new PaymentType(v))
          })
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  private loadCardIssuers(): Promise<CardIssuer[]> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/payment/card-issuers')
        .then((response) => {
          const result = []
          response.data.data.forEach((v) => {
            result.push(new CardIssuer(v))
          })
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  private get form() {
    return this.$refs.form as Vue & { validate: () => boolean }
  }

  private clickSave() {
    if (!this.form.validate()) {
      return
    }
    this.error = ''
    if (this.isNew) {
      PaymentAccountingRuleService.create(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not save payment accounting rule')
        })
    } else {
      this.data.id = this.id
      PaymentAccountingRuleService.update(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not save payment accounting rule')
        })
    }
  }

  private clickCancel() {
    this.$router.back()
  }

  private get paymentAccounts(): Account[] {
    const result = []
    this.accounts.forEach((a) => {
      if (a.isTypePayment) {
        result.push(a)
      }
    })
    return result
  }
}
